import React, { lazy } from 'react';
import { AuthLayoutController } from 'router/AuthLayoutController';
import {
  ConnectWallet,
  SignUp,
  SetPassword,
  SignIn,
  ConnectWalletRegistration,
  // raceRoutesAuth,
  UpdateEmail,
  UpdatePassword,
  profileRoutesAuthWrapper,
  RegistrationCongrats,
  // ConfirmEmailVault,
  // ConfirmEmailVaultVerify,
  paymentRoutes,
} from 'pages';
import { ConfirmRegistrationEmail, ConfirmUpdateEmail, ConfirmUpdatePassword } from 'src/pages/Auth/ConfirmComponents';
import { ForgotPassword } from 'pages/Auth/ForgotPassword';
import AuthorizedProvider from 'components/AuthComponents/AuthProvider/AuthorizedProvider';
import { PrivateRouteProvider } from 'components/AuthComponents/AuthProvider';
// import { KYC } from 'pages/Auth/KYC';
// import { SignUpLiquid } from 'pages/Auth/SignUpLiquid';
// import ConfirmPhoneRegistration from 'pages/Auth/ConfirmComponents/ConfirmPhoneRegistration';
// import { SignInLiquid } from 'pages/Auth/SignInLiquid';
// import { Navigate } from 'react-router-dom';
// import { AuthWithoutPaymentProvider } from 'providers/AuthWithoutPaymentProvider/AuthWithoutPaymentProvider';
// import { CreateLiquidWallet } from 'pages/Auth/CreateLiquidWallet';
import { ConfirmGemsTransactionVault } from 'pages/Profile/ConfirmGemsTransactionVault';
// import AuthorizedVaultProvider from 'components/AuthComponents/AuthProvider/AuthorizedVaultProvider';

const AuthLayoutComponent = lazy(() => import('components/AuthLayout'));

const authRouter = {
  path: '',
  element: <AuthLayoutComponent />,
  children: [
    // raceRoutesAuth,
    profileRoutesAuthWrapper,
    paymentRoutes,
    {
      path: 'wallet-connect',
      element: (
        <AuthLayoutController hasBackButton>
          <ConnectWallet />
        </AuthLayoutController>
      ),
      children: [],
    },
    // {
    //   path: 'liquid-wallet',
    //   children: [
    //     {
    //       index: true,
    //       element: <Navigate to="confirm-email" replace />,
    //     },
    //     {
    //       path: 'confirm-email',
    //       children: [
    //         {
    //           index: true,
    //           element: (
    //             <AuthLayoutController hasBackButton>
    //               <PrivateRouteProvider>
    //                 <ConfirmEmailVault />
    //               </PrivateRouteProvider>
    //             </AuthLayoutController>
    //           ),
    //         },
    //         {
    //           path: 'verify',
    //           element: (
    //             <AuthLayoutController hasBackButton>
    //               <PrivateRouteProvider>
    //                 <ConfirmEmailVaultVerify />
    //               </PrivateRouteProvider>
    //             </AuthLayoutController>
    //           ),
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      path: 'sign-up',
      children: [
        {
          path: 'code',
          element: (
            <AuthLayoutController hasBackButton>
              <ConfirmRegistrationEmail />
            </AuthLayoutController>
          ),
          children: [],
        },
        {
          path: 'set-password',
          element: <SetPassword />,
          children: [],
        },
        {
          index: true,
          element: (
            <AuthorizedProvider>
              <AuthLayoutController hasBackButton>
                <SignUp />
              </AuthLayoutController>
            </AuthorizedProvider>
          ),
        },
        {
          path: 'connect-wallet-registration',
          element: (
            <AuthorizedProvider>
              <AuthLayoutController hasBackButton>
                <ConnectWalletRegistration />
              </AuthLayoutController>
            </AuthorizedProvider>
          ),
        },
        {
          path: 'congrats',
          element: <RegistrationCongrats />,
        },
      ],
    },
    // {
    //   path: 'sign-up-liquid',
    //   children: [
    //     {
    //       index: true,
    //       element: (
    //         <AuthWithoutPaymentProvider>
    //           <AuthLayoutController hasBackButton>
    //             <SignUpLiquid />
    //           </AuthLayoutController>
    //         </AuthWithoutPaymentProvider>
    //       ),
    //     },
    //     {
    //       path: 'code',
    //       element: (
    //         <AuthLayoutController hasBackButton>
    //           <ConfirmPhoneRegistration />
    //         </AuthLayoutController>
    //       ),
    //       children: [],
    //     },
    //   ],
    // },
    // {
    //   path: 'create-liquid-wallet',
    //   children: [
    //     {
    //       index: true,
    //       element: (
    //         <AuthWithoutPaymentProvider>
    //           <AuthLayoutController hasBackButton>
    //             <CreateLiquidWallet />
    //           </AuthLayoutController>
    //         </AuthWithoutPaymentProvider>
    //       ),
    //     },
    //     {
    //       path: 'code',
    //       element: (
    //         <AuthLayoutController hasBackButton>
    //           <ConfirmPhoneRegistration />
    //         </AuthLayoutController>
    //       ),
    //       children: [],
    //     },
    //   ],
    // },
    {
      path: 'sign-in',
      element: (
        <AuthorizedProvider>
          <AuthLayoutController hasBackButton>
            <SignIn />
          </AuthLayoutController>
        </AuthorizedProvider>
      ),
      children: [],
    },
    // {
    //   path: 'sign-in-liquid',
    //   element: (
    //     <AuthorizedVaultProvider>
    //       <AuthLayoutController hasBackButton>
    //         <SignInLiquid />
    //       </AuthLayoutController>
    //     </AuthorizedVaultProvider>
    //   ),
    //   children: [],
    // },
    {
      path: 'confirm-purchase/gems',
      element: (
        <AuthLayoutController hasBackButton>
          <PrivateRouteProvider>
            <ConfirmGemsTransactionVault />
          </PrivateRouteProvider>
        </AuthLayoutController>
      ),
    },
    {
      path: 'update-email',
      children: [
        {
          path: 'code',
          element: (
            <AuthLayoutController hasBackButton>
              <PrivateRouteProvider>
                <ConfirmUpdateEmail />
              </PrivateRouteProvider>
            </AuthLayoutController>
          ),
        },
        {
          index: true,
          element: (
            <AuthLayoutController hasBackButton>
              <PrivateRouteProvider>
                <UpdateEmail />
              </PrivateRouteProvider>
            </AuthLayoutController>
          ),
        },
      ],
    },
    {
      path: 'update-password',
      children: [
        {
          path: 'code',
          element: (
            <AuthLayoutController hasBackButton>
              <AuthorizedProvider>
                <ConfirmUpdatePassword />
              </AuthorizedProvider>
            </AuthLayoutController>
          ),
        },
        {
          index: true,
          element: (
            <AuthLayoutController hasBackButton>
              <AuthorizedProvider>
                <UpdatePassword />
              </AuthorizedProvider>
            </AuthLayoutController>
          ),
        },
      ],
    },
    {
      path: 'forgot-password',
      children: [
        {
          index: true,
          element: (
            <AuthorizedProvider>
              <AuthLayoutController hasBackButton>
                <ForgotPassword />
              </AuthLayoutController>
            </AuthorizedProvider>
          ),
        },
      ],
    },
    // {
    //   path: 'kyc',
    //   children: [
    //     {
    //       index: true,
    //       element: (
    //         <PrivateRouteProvider>
    //           <AuthLayoutController hasBackButton>
    //             <KYC />
    //           </AuthLayoutController>
    //         </PrivateRouteProvider>
    //       ),
    //     },
    //   ],
    // },
  ],
};

export default authRouter;
