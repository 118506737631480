import React from 'react';
import { ControllerBaseLayout } from 'router/ControllerBaseLayout';
import { SupportFaq, SupportInstruction } from 'pages';
// import FaqLiquidWallet from './Faq/FaqLiquidWallet.component';

export const supportRoute = {
  path: 'support',
  children: [
    {
      path: 'instructions',
      element: (
        <ControllerBaseLayout bg="secondary" isHaveButtonBack={false}>
          <SupportInstruction />
        </ControllerBaseLayout>
      ),
    },
    {
      path: 'faq',
      children: [
        {
          index: true,
          element: (
            <ControllerBaseLayout bg="secondary" isHaveButtonBack={false}>
              <SupportFaq />
            </ControllerBaseLayout>
          ),
        },
        // {
        //   path: 'liquid-wallet',
        //   element: (
        //     <ControllerBaseLayout bg="secondary" isHaveButtonBack>
        //       <FaqLiquidWallet />
        //     </ControllerBaseLayout>
        //   ),
        // },
      ],
    },
  ],
};
